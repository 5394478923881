var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CContainer',{staticClass:"min-vh-100 pt-2",staticStyle:{"background":"rgb(255, 255, 255) url('img/filigrana.jpg') no-repeat scroll"},attrs:{"id":"doc"}},[_c('div',[_c('CRow',{staticClass:"pl-3 mt-2",staticStyle:{"justify-content":"center"}}),_c('VisualizzaDocumento'),_c('CRow',{staticClass:"min-vh-100",attrs:{"id":"RowExplorer"}},[_c('CCol',{staticClass:"file_manager",attrs:{"md":"3"}},[_vm._l((_vm.intermediari_list),function(folder){return _c('div',{key:folder.slug,staticClass:"folder parent pt-0 pl-2"},[(folder.visible == 'admin' && _vm.admin)?_c('div',{staticClass:"py-1",staticStyle:{"border-bottom":"1px solid lightgray"}},[_c('span',{staticClass:"icon_folder h5",class:{ highlight: _vm.dove_sono == folder.slug },staticStyle:{"white-space":"nowrap"},on:{"click":function($event){_vm.call_folder_list(folder);
        _vm.subAuto = false;
        _vm.dove_sono = folder.slug;
        _vm.color = 'white';}}},[_vm._v(" "+_vm._s(folder.nome))])]):_vm._e(),(folder.visible == 'all')?_c('div',{staticClass:"py-1",staticStyle:{"border-bottom":"1px solid lightgray"}},[_c('span',{staticClass:"icon_folder h5",class:{ highlight: _vm.dove_sono == folder.slug },staticStyle:{"white-space":"nowrap"},on:{"click":function($event){_vm.call_folder_list(folder);
        _vm.subAuto = false;
        _vm.dove_sono = folder.slug;
        _vm.color = 'white';}}},[_vm._v(" "+_vm._s(folder.nome))])]):_vm._e()])}),(!_vm.admin)?_c('div',_vm._l((_vm.documenti_list),function(folder){return _c('div',{key:folder.slug,staticClass:"folder parent pt-0 pl-2 py-1",staticStyle:{"border-bottom":"1px solid lightgray"}},[_c('span',{staticClass:"icon_folder h5",class:{ highlight: _vm.dove_sono == folder.slug },staticStyle:{"white-space":"nowrap"},on:{"click":function($event){_vm.call_folder_list(folder);
        _vm.subAuto = false;
        _vm.dove_sono = folder.slug;
        _vm.color = '';}}},[_vm._v(" "+_vm._s(folder.nome))]),_vm._l((folder.childs),function(items){return _c('li',{key:items.slug,staticClass:"folder h6 pl-3"},[_vm._v(" └ "),_c('span',{staticClass:"icon_folder",class:{ highlight: _vm.dove_sono == items.slug },staticStyle:{"white-space":"nowrap"},on:{"click":function($event){_vm.call_subfolder_list(items, folder);
        _vm.dove_sono = items.slug;}}},[_vm._v(_vm._s(items.nome))])])})],2)}),0):_vm._e(),_vm._l((_vm.folder_list),function(folder){return _c('div',{key:folder.slug,staticClass:"folder parent pt-0 pl-2 py-1",staticStyle:{"border-bottom":"1px solid lightgray"}},[_c('span',{staticClass:"icon_folder h5",class:{ highlight: _vm.dove_sono == folder.slug },staticStyle:{"white-space":"nowrap"},on:{"click":function($event){_vm.call_folder_list(folder);
        _vm.dove_sono = folder.slug;
        _vm.subAuto = true;
        _vm.color = '';}}},[_vm._v(" "+_vm._s(folder.nome))]),_vm._l((folder.childs),function(items){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.subAuto),expression:"subAuto"}],key:items.slug,staticClass:"folder h6 pl-3"},[_vm._v(" └ "),_c('span',{staticClass:"icon_folder",class:{ highlight: _vm.dove_sono == items.slug },staticStyle:{"white-space":"nowrap"},on:{"click":function($event){_vm.call_subfolder_list(items, folder);
        _vm.dove_sono = items.slug;}}},[_vm._v(_vm._s(items.nome))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(items.slug == 'ALTRE_GARANZIE'),expression:"items.slug == 'ALTRE_GARANZIE'"}],staticClass:"pl-4"},_vm._l((_vm.altre_gar),function(tipo){return _c('li',{key:tipo.index,staticClass:"folder h6 pl-3"},[_vm._v(" └ "),_c('span',{staticClass:"icon_folder",class:{ highlight: _vm.dove_sono == tipo },staticStyle:{"white-space":"nowrap"},on:{"click":function($event){_vm.filter_garanzie(tipo);
        _vm.dove_sono = tipo;}}},[_vm._v(_vm._s(tipo))])])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(items.slug == 'ALTRI_SERVIZI'),expression:"items.slug == 'ALTRI_SERVIZI'"}],staticClass:"pl-4"},_vm._l((_vm.altri_servizi),function(tipo){return _c('li',{key:tipo.index,staticClass:"folder h6"},[_vm._v(" └ "),_c('span',{staticClass:"icon_folder",class:{ highlight: _vm.dove_sono == tipo },staticStyle:{"white-space":"nowrap"},on:{"click":function($event){_vm.filter_servizi(tipo);
        _vm.dove_sono = tipo;}}},[_vm._v(_vm._s(tipo))])])}),0)])})],2)}),_vm._l((_vm.rami_list),function(folder){return _c('div',{key:folder.slug,staticClass:"folder parent pt-0 pl-2 py-1",staticStyle:{"border-bottom":"1px solid lightgray"}},[_c('span',{staticClass:"icon_folder h5",class:{ highlight: _vm.dove_sono == folder.slug },staticStyle:{"white-space":"nowrap"},on:{"click":function($event){_vm.call_folder_rami(folder);
        _vm.subAuto = false;
        _vm.dove_sono = folder.slug;}}},[_vm._v(" "+_vm._s(folder.nome))]),_vm._l((_vm.non_auto),function(tipo){return _c('li',{key:tipo.index,staticClass:"folder h6 pl-3"},[_vm._v(" └ "),_c('span',{staticClass:"icon_folder",class:{ highlight: _vm.dove_sono == tipo.name },staticStyle:{"white-space":"nowrap"},on:{"click":function($event){_vm.call_sub_rami(tipo.idTipo, tipo.name);
        _vm.dove_sono = tipo.name;}}},[_vm._v(_vm._s(tipo.name))]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.lista_sub_prod.length > 0 &&
          tipo.idTipo == _vm.lista_sub_prod[0].idTipo
          ),expression:"lista_sub_prod.length > 0 &&\n          tipo.idTipo == lista_sub_prod[0].idTipo\n          "}],staticClass:"pl-4"},_vm._l((_vm.lista_sub_prod),function(subcat){return _c('li',{key:subcat.index,staticClass:"folder h6 pl-3"},[_vm._v(" └ "),_c('span',{staticClass:"icon_folder",class:{ highlight: _vm.dove_sono == subcat.name },staticStyle:{"white-space":"nowrap"},on:{"click":function($event){_vm.filter_rami(subcat.name);
        _vm.dove_sono = subcat.name;}}},[_vm._v(_vm._s(subcat.name))])])}),0)])})],2)})],2),_c('CCol',{style:({ 'background-color': _vm.color }),attrs:{"md":"9"}},[_c('div',[(_vm.breadcrumbs.length > 0)?_c('div',{staticClass:"pt-3",attrs:{"id":"breadcrumbs"}},[_c('span',{staticStyle:{"border":"1px solid","border-radius":"3px","padding":"2px"}},[_c('font-awesome-icon',{attrs:{"icon":_vm.breadcrumbs[0][1]}}),_vm._v(" "+_vm._s(_vm.breadcrumbs[0][0])+" ")],1),(_vm.breadcrumbs.length >= 2)?_c('span',{staticStyle:{"padding":"2px"}},[_c('i',{staticClass:"fas fa-chevron-right"})]):_vm._e(),(_vm.breadcrumbs.length >= 2)?_c('span',{staticStyle:{"border":"1px solid","border-radius":"3px","padding":"2px"}},[_vm._v(_vm._s(_vm.breadcrumbs[1][0]))]):_vm._e(),(_vm.breadcrumbs.length == 3)?_c('span',{staticStyle:{"padding":"2px"}},[_c('i',{staticClass:"fas fa-chevron-right"})]):_vm._e(),(_vm.breadcrumbs.length == 3)?_c('span',{staticStyle:{"border":"1px solid","border-radius":"3px","padding":"2px"}},[_vm._v(_vm._s(_vm.breadcrumbs[2][0]))]):_vm._e(),(_vm.settore == 'DOCUMENTI')?_c('div',{staticClass:"pt-5"},[_c('span',_vm._l((_vm.documenti_list),function(folder){return _c('div',{key:folder.name,staticClass:"pt-0"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(folder.subFolder),expression:"folder.subFolder"}]},[_c('table',{staticClass:"table table-striped table-bordered table-hover",staticStyle:{"border":"0 !important"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",staticStyle:{"border-left":"0 !important","border-right":"0 !important"}},[_vm._v(" Seleziona una cartella ")]),_c('th',{staticStyle:{"border-left":"0 !important","border-right":"0 !important"}})])]),_c('tbody',_vm._l((folder.childs),function(items){return _c('tr',{key:items.nome,staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.call_subfolder_list(items, folder);
        _vm.dove_sono = items.slug;}}},[_c('td',{staticStyle:{"border-left":"0 !important","border-right":"0 !important"}},[_c('span',{staticClass:"icon_folder pr-2"}),_vm._v(_vm._s(items.nome)+" ")]),_c('td',{staticClass:"text-right",staticStyle:{"border-left":"0 !important","border-right":"0 !important"}})])}),0)])])])}),0)]):_vm._e(),(_vm.settore == 'SETTORI 1 E 2')?_c('div',{staticClass:"pt-5"},[_c('span',_vm._l((_vm.folder_list),function(folder){return _c('div',{key:folder.name,staticClass:"pt-0"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(folder.subFolder),expression:"folder.subFolder"}]},[_c('table',{staticClass:"table table-striped table-bordered table-hover",staticStyle:{"border":"0 !important"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",staticStyle:{"border-left":"0 !important","border-right":"0 !important"}},[_vm._v(" Seleziona una cartella ")]),_c('th',{staticStyle:{"border-left":"0 !important","border-right":"0 !important"}})])]),_c('tbody',_vm._l((folder.childs),function(items){return _c('tr',{key:items.nome,staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.call_subfolder_list(items, folder);
        _vm.dove_sono = items.slug;}}},[_c('td',{staticStyle:{"border-left":"0 !important","border-right":"0 !important"}},[_c('span',{staticClass:"icon_folder pr-2"}),_vm._v(_vm._s(items.nome)+" ")]),_c('td',{staticClass:"text-right",staticStyle:{"border-left":"0 !important","border-right":"0 !important"}})])}),0)]),_c('div',{staticClass:"text-left"},[_c('CButton',{staticStyle:{"color":"white","padding":"8px"},attrs:{"color":"primary","square":"","size":"sm"},on:{"click":function($event){return _vm.download_excel_auto()}}},[_c('i',{staticClass:"fas fa-file-excel fa-2x"}),_vm._v("   Esporta Catalogo")])],1)])])}),0)]):_vm._e(),(_vm.settore == 'RAMI')?_c('div',{staticClass:"pt-2"},[_c('span',[_c('div',{staticClass:"pt-0"},[_c('div',[_c('table',{staticClass:"table table-striped table-bordered table-hover",staticStyle:{"border":"0 !important"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-center",staticStyle:{"border-left":"0 !important","border-right":"0 !important"}},[_vm._v(" Effettua una scelta ")]),_c('th',{staticStyle:{"border-left":"0 !important","border-right":"0 !important"}})])]),_c('tbody',_vm._l((_vm.non_auto),function(tipo){return _c('tr',{key:tipo.index,staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.call_sub_rami(tipo.idTipo, tipo.name);
        _vm.dove_sono = tipo.name;}}},[_c('td',{staticStyle:{"border-left":"0 !important","border-right":"0 !important"}},[_c('span',{staticClass:"icon_folder pr-2"}),_vm._v(_vm._s(tipo.name)+" ")]),_c('td',{staticClass:"text-right",staticStyle:{"border-left":"0 !important","border-right":"0 !important"}})])}),0)])]),_c('div',{staticClass:"text-left"},[_c('CButton',{staticStyle:{"color":"white","padding":"8px"},attrs:{"color":"primary","square":"","size":"sm"},on:{"click":function($event){return _vm.download_excel_rami()}}},[_c('i',{staticClass:"fas fa-file-excel fa-2x"}),_vm._v("   Esporta Catalogo")])],1)])])]):_vm._e()]):_c('div',{staticClass:"pt-5 display-4"},[_c('font-awesome-icon',{attrs:{"icon":"arrow-left"}}),_vm._v(" Effettua una selezione ")],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.vuoto && _vm.settore != 'RAMI'),expression:"vuoto && settore != 'RAMI'"}],staticClass:"pt-5 h4 text-center"},[_vm._v(" - Al momento non ci sono documenti disponibili - ")]),(_vm.settore === 'ORGANIGRAMMA ABY BROKER')?_c('div',{staticClass:"pt-2"},[_c('CDataTable',{ref:"tabella_ORGANIGRAMMA ABY",attrs:{"id":"int_table","items":_vm.files,"fields":_vm.fields_ORGANIGRAMMA,"sorter":"","hover":"","border":"","itemsPerPage":20,"pagination":"","table-filter":{
          placeholder: 'Ricerca...',
          label: 'Ricerca:',
        },"striped":"","items-per-page-select":{ label: 'Risultati per pagina' },"noItemsView":{ noItems: ' ' }},scopedSlots:_vm._u([{key:"Download",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('a',{attrs:{"href":item.Nomefile},on:{"click":function($event){$event.preventDefault();_vm.preview(item.Nomefile, 'ORGANIGRAMMA');
        _vm.titoloModale(_vm.dove_sono, item.Descrizione);}}},[_c('i',{staticClass:"fas fa-download fa-2x"})])])]}}],null,false,1168691184)})],1):_vm._e(),(_vm.settore === 'WHISTLEBLOWING')?_c('div',{staticClass:"pt-2"},[_c('CDataTable',{ref:"tabella_WHISTLEBLOWING",attrs:{"id":"WB_table","items":_vm.files,"fields":_vm.fields_WHISTLEBLOWING,"sorter":"","hover":"","border":"","itemsPerPage":20,"pagination":"","table-filter":{
          placeholder: 'Ricerca...',
          label: 'Ricerca:',
        },"striped":"","items-per-page-select":{ label: 'Risultati per pagina' },"noItemsView":{ noItems: ' ' }},scopedSlots:_vm._u([{key:"Download",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('a',{attrs:{"href":item.Nomefile},on:{"click":function($event){$event.preventDefault();_vm.preview(item.Nomefile, 'WHISTLEBLOWING');
        _vm.titoloModale(_vm.dove_sono, item.Descrizione);}}},[_c('i',{staticClass:"fas fa-download fa-2x"})])])]}}],null,false,3996630871)})],1):_vm._e(),((_vm.settore === 'CIRCOLARI INTERNE' && _vm.vuoto == false) ||
          (_vm.settore === 'CIRCOLARI OPERATIVE' && _vm.vuoto == false)
          )?_c('div',{staticClass:"pt-2"},[_c('CDataTable',{ref:"tabella_CIRCOLARI",attrs:{"id":"int_table","items":_vm.files,"fields":_vm.fields_CIRCOLARI,"sorter":"","hover":"","border":"","itemsPerPage":20,"pagination":"","table-filter":{
          placeholder: 'Ricerca...',
          label: 'Ricerca:',
        },"striped":"","items-per-page-select":{ label: 'Risultati per pagina' },"noItemsView":{ noItems: ' ' }},scopedSlots:_vm._u([{key:"Download",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('a',{attrs:{"href":item.Id},on:{"click":function($event){$event.preventDefault();_vm.preview(item.Id, 'CIRCOLARI');
        _vm.titoloModale(_vm.dove_sono, item.Titolo);}}},[_c('i',{staticClass:"fas fa-download fa-2x"})])])]}}],null,false,1368412547)})],1):_vm._e(),(_vm.settore === 'INTERMEDIARIO' ||
          (_vm.settore === 'PRECONTRATTUALE' && _vm.vuoto == false)
          )?_c('div',{staticClass:"pt-2"},[_c('CDataTable',{ref:"tabella_doc",attrs:{"itemsPerPage":20,"id":"int_table","items":_vm.files,"fields":_vm.fields_DOCUMENTI,"hover":"","border":"","striped":"","noItemsView":{ noItems: ' ' }},scopedSlots:_vm._u([{key:"visualizza",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"py-2 text-center"},[(item.Nomefile !== '')?_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){_vm.preview(item.Nomefile, 'INTERMEDIARIO');
        _vm.titoloModale(_vm.dove_sono, item.Descrizione);}}},[_vm._v(" Visualizza ")]):_vm._e()],1)]}}],null,false,105181858)})],1):_vm._e(),(_vm.settore === 'INTERMEDIARI EMITTENTI')?_c('div',{staticClass:"pt-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"}),_c('div',{staticClass:"col-sm-4 text-center"}),_c('div',{staticClass:"col-sm-4 text-right"},[_vm._v(" Totale Intermediari Emittenti: "),_c('b',[_vm._v(_vm._s(_vm.files.length))])])]),_c('CDataTable',{ref:"tabella_doc",attrs:{"id":"emittenti_table","items":_vm.files,"fields":_vm.admin
          ? _vm.fields_INTERMEDIARI_EMITTENTI_ADMIN
          : _vm.fields_INTERMEDIARI_EMITTENTI,"sorter":"","hover":"","border":"","itemsPerPage":20,"pagination":"","table-filter":{
          placeholder: 'Ricerca...',
          label: 'Ricerca:',
        },"striped":"","items-per-page-select":{ label: 'Risultati per pagina' },"noItemsView":{ noItems: ' ' }},scopedSlots:_vm._u([{key:"Descrizione",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.Descrizione.split(" ")[0])+" ")])]}},{key:"RUI",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.RUI))])]}},{key:"Quanti_prodotti_in_uso",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('router-link',{attrs:{"to":{
          name: 'DettagliIntermediario',
          params: {
            intermediario: item,
            elenco: _vm.files,
          },
        }}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Clicca per visualizzare i Prodotti in uso'
          ),expression:"'Clicca per visualizzare i Prodotti in uso'\n          "}],attrs:{"size":"sm","color":"primary","variant":"outline"}},[_vm._v(" "+_vm._s(item.Quanti_prodotti_in_uso)+" "+_vm._s(item.Quanti_prodotti_in_uso == 1 ? "prodotto" : "prodotti")+" in uso ")])],1)],1)]}},{key:"POG",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"py-2 text-center"},[(item.POG !== '')?_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){_vm.preview(item.POG, 'INTERMEDIARIO');
        _vm.titoloModale('EMITTENTE', item.Descrizione, 'POG');}}},[_vm._v(" Visualizza ")]):_vm._e()],1)]}},{key:"RilieviDiAby",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"py-2 text-center"},[_c('router-link',{attrs:{"to":{
          name: 'Rilievi_Audit',
          params: {
            intermediario: item,
            oldData: _vm.files,
            origine: 'Emittenti',
            richiesta: 'Aby',
          },
        }}},[_c('CButton',{attrs:{"size":"sm","color":"primary","variant":"outline"}},[_vm._v(" Mostra ")])],1)],1)]}},{key:"RilieviIntermediari",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"py-2 text-center"},[_c('router-link',{attrs:{"to":{
          name: 'Rilievi_Audit',
          params: {
            intermediario: item,
            oldData: _vm.files,
            origine: 'Emittenti',
            richiesta: 'Int',
          },
        }}},[_c('CButton',{attrs:{"size":"sm","color":"primary","variant":"outline"}},[_vm._v(" Mostra ")])],1)],1)]}},{key:"Audit",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"py-2 text-center"},[_c('router-link',{attrs:{"to":{
          name: 'Rilievi_Audit',
          params: {
            intermediario: item,
            oldData: _vm.files,
            origine: 'Emittenti',
            richiesta: 'Aud',
          },
        }}},[_c('CButton',{attrs:{"size":"sm","color":"primary","variant":"outline"}},[_vm._v(" Mostra ")])],1)],1)]}}],null,false,4281418064)})],1):_vm._e(),(_vm.settore === 'INTERMEDIARI PROPONENTI')?_c('div',{staticClass:"pt-2"},[_c('p',{staticClass:"text-right"},[_vm._v(" Totale Intermediari Proponenti: "),_c('b',[_vm._v(_vm._s(_vm.files.length))])]),_c('CDataTable',{ref:"tabella_doc",attrs:{"id":"proponenti_table","items":_vm.files,"fields":_vm.fields_INTERMEDIARI_PROPONENTI,"sorter":"","hover":"","border":"","itemsPerPage":20,"pagination":"","table-filter":{
          placeholder: 'Ricerca...',
          label: 'Ricerca:',
        },"striped":"","items-per-page-select":{ label: 'Risultati per pagina' },"noItemsView":{ noItems: ' ' }},scopedSlots:_vm._u([{key:"Descrizione",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.Descrizione.split(" ")[0])+" ")])]}},{key:"RUI",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.RUI))])]}},{key:"Quante_unita_operative_attive",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_c('router-link',{attrs:{"to":{
          name: 'DettagliProponente',
          params: {
            intermediario: item,
            proponenti: _vm.files,
          },
        }}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Clicca per visualizzare le unità operative'
          ),expression:"'Clicca per visualizzare le unità operative'\n          "}],attrs:{"size":"sm","color":"primary","variant":"outline"}},[_vm._v(" "+_vm._s(item.Quante_unita_operative_attive)+" "+_vm._s(item.Quante_unita_operative_attive == 1 ? "Unità operativa" : "Unità operative")+" ")])],1)],1)]}},{key:"POG",fn:function(ref){
          var item = ref.item;
return [_c('td',{staticClass:"py-2 text-center"},[(item.POG !== '')?_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){_vm.preview(item.POG, 'INTERMEDIARIO');
        _vm.titoloModale('PROPONENTE', item.Descrizione, 'POG');}}},[_vm._v(" Visualizza ")]):_vm._e()],1)]}},{key:"RilieviDiAby",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"py-2 text-center"},[_c('router-link',{attrs:{"to":{
          name: 'Rilievi_Audit',
          params: {
            intermediario: item,
            oldData: _vm.files,
            origine: 'Proponenti',
            richiesta: 'Aby',
          },
        }}},[_c('CButton',{attrs:{"size":"sm","color":"primary","variant":"outline"}},[_vm._v(" Mostra ")])],1)],1)]}},{key:"RilieviIntermediari",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"py-2 text-center"},[_c('router-link',{attrs:{"to":{
          name: 'Rilievi_Audit',
          params: {
            intermediario: item,
            oldData: _vm.files,
            origine: 'Proponenti',
            richiesta: 'Int',
          },
        }}},[_c('CButton',{attrs:{"size":"sm","color":"primary","variant":"outline"}},[_vm._v(" Mostra ")])],1)],1)]}},{key:"Audit",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"py-2 text-center"},[_c('router-link',{attrs:{"to":{
          name: 'Rilievi_Audit',
          params: {
            intermediario: item,
            oldData: _vm.files,
            origine: 'Proponenti',
            richiesta: 'Aud',
          },
        }}},[_c('CButton',{attrs:{"size":"sm","color":"primary","variant":"outline"}},[_vm._v(" Mostra ")])],1)],1)]}}],null,false,1048421852)})],1):_vm._e(),(_vm.settore === 'UNITA OPERATIVE')?_c('div',{staticClass:"pt-2"},[_c('p',{staticClass:"text-right"},[_vm._v(" Totale Unità Operative: "),_c('b',[_vm._v(_vm._s(_vm.files.length))])]),_c('CDataTable',{ref:"tabella_doc",attrs:{"id":"UO_table","items":_vm.files,"fields":_vm.fields_UO,"sorter":"","hover":"","border":"","itemsPerPage":20,"pagination":"","table-filter":{
          placeholder: 'Ricerca...',
          label: 'Ricerca:',
        },"striped":"","items-per-page-select":{ label: 'Risultati per pagina' },"noItemsView":{ noItems: ' ' }},scopedSlots:_vm._u([{key:"RUI",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.RUI))])]}},{key:"RilieviDiAby",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"py-2 text-center"},[_c('router-link',{attrs:{"to":{
          name: 'Rilievi_Audit',
          params: {
            intermediario: item,
            oldData: _vm.files,
            origine: 'UO',
            richiesta: 'Aby',
          },
        }}},[_c('CButton',{attrs:{"size":"sm","color":"primary","variant":"outline"}},[_vm._v(" Mostra ")])],1)],1)]}},{key:"RilieviIntermediari",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"py-2 text-center"},[_c('router-link',{attrs:{"to":{
          name: 'Rilievi_Audit',
          params: {
            intermediario: item,
            oldData: _vm.files,
            origine: 'UO',
            richiesta: 'Int',
          },
        }}},[_c('CButton',{attrs:{"size":"sm","color":"primary","variant":"outline"}},[_vm._v(" Mostra ")])],1)],1)]}},{key:"Audit",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"py-2 text-center"},[_c('router-link',{attrs:{"to":{
          name: 'Rilievi_Audit',
          params: {
            intermediario: item,
            oldData: _vm.files,
            origine: 'UO',
            richiesta: 'Aud',
          },
        }}},[_c('CButton',{attrs:{"size":"sm","color":"primary","variant":"outline"}},[_vm._v(" Mostra ")])],1)],1)]}}],null,false,1674664397)})],1):_vm._e(),(_vm.settore === 'RC AUTO')?_c('div',{staticClass:"pt-2"},[_c('CDataTable',{ref:"tabella_doc",attrs:{"id":"rc_table","items":_vm.files,"fields":_vm.fields_RCA,"itemsPerPage":20,"sorter":"","hover":"","border":"","pagination":"","table-filter":{
          placeholder: 'Ricerca...',
          label: 'Ricerca:',
        },"striped":"","items-per-page-select":{ label: 'Risultati per pagina' },"noItemsView":{ noItems: ' ' }},scopedSlots:_vm._u([{key:"visualizza_POG",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"py-2 text-center"},[(item.Pog !== '')?_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){_vm.preview(item.Pog, 'RCA');
        _vm.titoloModale('RC AUTO', item.Descrizione, 'POG');}}},[_vm._v(" Visualizza ")]):_vm._e()],1)]}},{key:"visualizza",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"py-2 text-center"},[(item.Nomefile !== '')?_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){_vm.preview(item.Nomefile, 'RCA');
        _vm.titoloModale(
          'RC AUTO',
          item.Descrizione,
          'SET INFORMATIVO'
        );}}},[_vm._v(" Visualizza ")]):_vm._e()],1)]}}],null,false,2461345376)})],1):_vm._e(),(_vm.settore === 'SERVIZI NON ASSICURATIVI')?_c('div',{staticClass:"pt-2"},[_c('CDataTable',{ref:"tabella_doc",attrs:{"id":"altre_table","items":_vm.files,"fields":_vm.fields_SERVIZI,"itemsPerPage":20,"sorter":"","hover":"","border":"","pagination":"","column-filter-value":{ Tipo: _vm.filtro_gar },"table-filter":{
          placeholder: 'Ricerca...',
          label: 'Ricerca:',
        },"striped":"","items-per-page-select":{ label: 'Risultati per pagina' },"noItemsView":{ noItems: ' ' }},scopedSlots:_vm._u([{key:"visualizza",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"py-2 text-center"},[(item.Nomefile !== '')?_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){_vm.preview(item.Nomefile, 'NON_ASSICURATIVI');
        _vm.titoloModale(
          item.Tipo,
          item.Descrizione,
          'SET INFORMATIVO'
        );}}},[_vm._v(" Visualizza ")]):_vm._e()],1)]}}],null,false,310723760)})],1):_vm._e(),(_vm.settore === 'ALTRE GARANZIE')?_c('div',{staticClass:"pt-2"},[_c('CDataTable',{ref:"tabella_doc",attrs:{"id":"altre_table","items":_vm.files,"fields":_vm.fields_ALTRE,"itemsPerPage":20,"sorter":"","hover":"","pagination":"","border":"","column-filter-value":{ Tipo: _vm.filtro_gar },"table-filter":{
          placeholder: 'Ricerca...',
          label: 'Ricerca:',
        },"striped":"","items-per-page-select":{ label: 'Risultati per pagina' },"noItemsView":{ noItems: ' ' }},scopedSlots:_vm._u([{key:"visualizza_POG",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"py-2 text-center"},[(item.Pog !== '')?_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){_vm.preview(item.Pog, 'ALTRE_GARANZIE');
        _vm.titoloModale(item.Tipo, item.Descrizione, 'POG');}}},[_vm._v(" Visualizza ")]):_vm._e()],1)]}},{key:"visualizza",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"py-2 text-center"},[(item.Nomefile !== '')?_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){_vm.preview(item.Nomefile, 'ALTRE_GARANZIE');
        _vm.titoloModale(
          item.Tipo,
          item.Descrizione,
          'SET INFORMATIVO'
        );}}},[_vm._v(" Visualizza ")]):_vm._e()],1)]}}],null,false,3212190464)})],1):_vm._e(),(_vm.settore === 'RAMI_DATA' && !_vm.vuoto)?_c('div',{staticClass:"pt-2"},[_c('CDataTable',{ref:"tabella_doc",attrs:{"itemsPerPage":20,"id":"rami_table","items":_vm.files,"fields":_vm.fields_RAMI,"sorter":"","hover":"","pagination":"","border":"","column-filter-value":{ Tipo: _vm.filtro_rami },"table-filter":{
          placeholder: 'Ricerca...',
          label: 'Ricerca:',
        },"striped":"","items-per-page-select":{ label: 'Risultati per pagina' },"noItemsView":{ noItems: ' ' }},scopedSlots:_vm._u([{key:"visualizza_POG",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"py-2 text-center"},[(item.Pog !== '')?_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){_vm.preview(item.Pog, 'RAMI');
        _vm.titoloModale(item.Tipo, item.Descrizione, 'POG');}}},[_vm._v(" Visualizza ")]):_vm._e()],1)]}},{key:"visualizza",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"py-2 text-center"},[(item.Nomefile !== '')?_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){_vm.preview(item.Nomefile, 'RAMI');
        _vm.titoloModale(
          item.Tipo,
          item.Descrizione,
          'SET INFORMATIVO'
        );}}},[_vm._v(" Visualizza ")]):_vm._e()],1)]}},{key:"SchedaTecnica",fn:function(ref){
        var item = ref.item;
return [_c('td',{staticClass:"py-2 text-center"},[(item.Nomefile == 'prova')?_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){_vm.preview(item.Nomefile, 'RAMI');
        _vm.titoloModale(
          item.Tipo,
          item.Descrizione,
          'SET INFORMATIVO'
        );}}},[_vm._v(" Visualizza ")]):_vm._e()],1)]}}],null,false,2399733593)})],1):_vm._e()])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }